.layout-wrapper {
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
  min-height: 100vh;

  .menu_list{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .layout-content {
    max-width: 1280px;
    margin: 132px 80px 24px 80px;
  }

  .logo{
    width: 40px;
    height: 40px;
    margin: 16px 24px 24px 24px;
  }

  .layout_page {
    max-width: 1280px;;
    height: 88px;
    margin: 0 80px 24px 80px;
    background-color: white;
    position: fixed;
    top: 24px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    z-index: 10; /* Чтобы хедер был поверх другого контента */
    box-shadow: 0 0 40px 1px var(--shadow-color);
    border-radius: var(--border-radius);
  }
  .user-image{
    width: 40px;
    height: 40px;
  }

  .avatar_button{
    height: 40px;
    padding: 0;
    width: 40px;
    background: transparent !important;
    margin-right: 20px;
  }

  .layout-title{
    color: var(--prime-color);
    font-size: 24px;
    font-weight: 500;
    &:hover{
      color: var(--secondary-color);
    }
  }

  .layout-title-wrapper{
    display: flex;
    align-items: center;
  }

  .menu_group {
    display: flex;
    gap: 20px;
  }

  .menu_button {
    background-color: transparent;
    color: var(--prime-color);
    border: none;
    padding: 10px 10px;
    font-size: 16px;
    cursor: pointer;
    width: auto;
    transition: color 0.3s;
    text-decoration: none;
  }

  .menu_button:hover {
    color: #4285f4;
  }

  .active {
    color: #4285f4;
    font-weight: bold;
  }

  .balls_button {
    background-color: #4285f4;
    color: white;
    border: none;
    padding: 10px 20px 10px 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }

  .balls_button:hover {
    background-color: #3367d6;
  }
  .right_group {
    margin-left: auto;
  }
}

.menu_dropdown{
  background-color: var(--prime-color) !important;
  border-radius: 20px !important;
}
.menu_dropdown_label{
  color: var(--white-color) !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}
.menu_dropdown_item{
  color: var(--highlight-color) !important;
  font-weight: 500 !important;
  font-size: 16px !important;

  &:hover{
    color: var(--white-color) !important;
    background-color: transparent !important;
    text-decoration: underline;
    text-decoration-color: var(--border-color);
  }
}

.menu_dropdown_exit{
  margin-top: 10px;
  margin-bottom: 10px;
}

.menu_dropdown_balls{

}